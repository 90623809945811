.centerIcon {
    width: 50px;
    height: 50px;
    border-radius: 50%;

    position: relative;
    left: 50%;
    transform: translateX(-50%);
}
@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}
@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.one {
    opacity: 0;
    height: 25px;
    width: 25px;
}
.circle {
    --size: 50px;
    --offset: 100px;
    width: var(--size);
    aspect-ratio: 1;
    position: absolute;
    border-radius: 50%;
    transform: translate(
        calc(cos(var(--degrees)) * var(--offset)),
        calc(sin(var(--degrees)) * var(--offset))
    );
    display: flex;
    justify-content: center;
    align-items: center;
}

.circle:nth-of-type(1) {
    --degrees: 0;
}
.circle:nth-of-type(2) {
    --degrees: 60deg;
}
.circle:nth-of-type(3) {
    --degrees: 120deg;
}
.circle:nth-of-type(4) {
    --degrees: 180deg;
}
.circle:nth-of-type(5) {
    --degrees: 240deg;
}
.circle:nth-of-type(6) {
    --degrees: 300deg;
}

.circle:nth-of-type(7) {
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;
}
